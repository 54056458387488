import React from "react";
import { Fade, Zoom } from "react-reveal";

const StaffingDescription = ({
  headingOne,
  headingTwo,
  description,
  noMargin,
  image,
  isHeightAuto,
  grayBack,
}) => {
  return (
    <Fade cascade>
      <div
        className={`section-br p-top-3em p-bottom-3em ${
          grayBack ? "gray-back section m-top-10px " : "section m-top-10px"
        }`}
      >
        <div className="staffing-wrapper">
          <div className="staffing-desc-wrapper w-90-wrapper">
            <h2 className="heading">
              {headingOne}
              <br></br> {headingTwo}
            </h2>
            <p className="para staffing-para">{description}</p>
          </div>
          <div className="image-wrapper">
            {image ? <img src={image} alt="Description"></img> : ""}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default StaffingDescription;
