import React from "react";
import Fade from "react-reveal/Fade";
// components
import StaffingTechnicalCapabilitiesItem from "./staffingTechnicalCapabilitiesItem";
import { useLanguage } from "../../languageContext";

const StaffingTechnicalCapabilities = ({ heading, paragraph, listItems }) => {
  const currentLanguage = useLanguage();
  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="staffing-technical-capabilities-wrapper">
          <h2 className="heading ">{heading}</h2>
          <Fade bottom>
            <p className="para">{paragraph}</p>
          </Fade>
          <div className="technical-items-wrapper">
            {listItems &&
              listItems.map(({ heading, paragraph, icons }) => (
                <>
                  <StaffingTechnicalCapabilitiesItem
                    heading={heading}
                    paragraph={paragraph}
                    icon={icons}
                    currentLanguage={currentLanguage}
                  ></StaffingTechnicalCapabilitiesItem>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffingTechnicalCapabilities;
