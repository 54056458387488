import React from "react";
const StaffingTechnicalCapabilitiesItem = ({
  heading,
  paragraph,
  icon,
  currentLanguage,
}) => {
  console.log(icon);
  return (
    <div className="technical-item">
      <img
        src={
          icon && icon.length > 0
            ? currentLanguage === "en"
              ? icon[0].url
              : currentLanguage === "in"
              ? icon[1].url
              : currentLanguage === "ja"
              ? icon[2].url
              : icon[0].url
            : ""
        }
        alt="Icon"
        className="technical-item-icon"
      />
      <h3 className="heading no-margin semi-title">{heading}​</h3>
      <h4 className="para no-margin">{paragraph}</h4>
    </div>
  );
};

export default StaffingTechnicalCapabilitiesItem;
