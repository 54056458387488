import React from "react";
import { Link } from "gatsby";
import { useLanguage } from "../../languageContext";
const StaffingInterested = ({ heading, para }) => {
  const currentLanguage = useLanguage();
  return (
    <div className="section">
      <div className="container">
        <div className="staffing-interested-section">
          <div className="heading">INTERESTED IN {heading}</div>
          <div className="para">{para ? para : ""}</div>
          <div className="m-top-20px">
            <Link to="/contactus/">
              <div className="btn-container">
                <button
                  className={`primary-btn bg-${
                    currentLanguage ? currentLanguage : "en"
                  }`}
                >
                  Get In Touch
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffingInterested;
