import React from "react";
import WipeBtn from "../../components/atoms/buttons/WipeBtn";
import Fade from "react-reveal/Fade";

import { useLanguage } from "../../languageContext";

const StaffingHeader = ({
  heading,
  paragraph,
  image,
  btnTxt,
  paragraphTwo,
  url,
  dassaultImg,
  dassaultTxt,
}) => {
  const currentLanguage = useLanguage();
  return (
    <div className="section staffing-section section-br p-bottom-3em">
      <div
        className={`bg-primary-slanted bg-${
          currentLanguage ? currentLanguage : "en"
        }`}
      >
        <div className="container staffing-container w-90-wrapper">
          <div className="staffing-info-wrapper">
            <Fade bottom>
              <h1 className="heading heading-bottom-space">{heading}</h1>
            </Fade>
            <p className="para common-bottom-space">{paragraph}</p>
            <p className="para common-bottom-space"> {paragraphTwo}</p>
            <div className="m-top-10px">
              <WipeBtn text={btnTxt} url={url}></WipeBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="container-for-dassault-icon">
        <div className="staffing-image-wrapper">
          <img
            src={
              image
                ? image
                : "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
            }
            alt="Staffing"
          />
        </div>

        {dassaultImg ? (
          <div className="w-90-wrapper element-staffing">
            <img
              alt="dassault icon "
              className="dassault-icon"
              src={dassaultImg}
            />
            <h2 className="heading semi-title title-dassault m-top-10px">
              {dassaultTxt}
            </h2>
          </div>
        ) : (
          ""
        )}
        {/* <div className="para semi-para">We manufacture and customize lighting fixtures</div> */}
      </div>
    </div>
  );
};

export default StaffingHeader;
