import React from "react";
import testimonialIcon from "../../images/testimonialicon.svg";

const StaffingTestimonial = () => {
  return (
    <div className="section m-top-10">
      <div className="container">
        <div className="staffing-testimonial-section bg-light-blue">
          <img
            className="icon"
            src={testimonialIcon}
            alt="Testimonial Icon"
          ></img>
          <p className="testimonial-content semi-title">
            “If you talk to a man in a language he understands, that goes to his
            head. If you talk to him in his own<br></br> language, that goes to
            his heart.” <br></br>~ Nelson Mandela
          </p>
        </div>
      </div>
    </div>
  );
};

export default StaffingTestimonial;
